import React, {useState, useEffect, useRef} from 'react';
import {Grid, TextField, Typography} from '@mui/material';
import {useStyles} from './styles';
import InfoModal from './InfoModal';

type Props = {
  label?: string;
  value?: string | number;
  customStyle?: React.CSSProperties;
  textLength?: number;
  multiline?: boolean;
  required?: boolean;
};

export const CustomTextFieldTP = ({
  label,
  value,
  customStyle,
  textLength = 20,
  multiline = false,
  required = false,
}: Props) => {
  const classes = useStyles();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showMoreTextModal, setShowMoreTextModal] = useState(false);
  const textFieldRef = useRef<HTMLTextAreaElement | null>(null);

  const stringValue = value != null ? value.toString() : '';
  const displayValue =
    stringValue.length > textLength
      ? `${stringValue.substring(0, textLength)}...`
      : stringValue;

  useEffect(() => {
    if (textFieldRef.current && multiline) {
      const element = textFieldRef.current;
      const hasOverflowed = element.scrollHeight > element.clientHeight;
      setIsOverflowing(hasOverflowed);
    }
  }, [stringValue, multiline]);

  const handleMoreClick = () => {
    setShowMoreTextModal(true);
  };

  if (multiline) {
    return (
      <div
        style={{
          paddingTop: 'env(safe-area-inset-top)',
          paddingBottom: 'env(safe-area-inset-bottom)',
          paddingLeft: 'env(safe-area-inset-left)',
          paddingRight: 'env(safe-area-inset-right)',
        }}>
        <Grid
          container
          spacing={2}
          alignItems="center" // Align items to the start
          className={classes.textFieldcontainer}
          style={customStyle}>
          {label && (
            <Grid item>
              <Typography
                className={classes.textFieldLabel}
                style={{paddingBottom: '30px'}}>
                {label}
                {required && <span style={{color: 'red'}}> *</span>}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <TextField
              value={stringValue}
              multiline
              rows={3}
              inputRef={textFieldRef}
              InputProps={{
                readOnly: true,
                className: classes.root,
                inputProps: {
                  style: {
                    paddingTop: '8px',
                    paddingLeft: '10px',
                  },
                },
                style: {
                  height: '75px',
                  alignItems: 'flex-start',
                },
              }}
              style={{
                height: '75px',
                width: '100%',
              }}
            />
          </Grid>
        </Grid>
        {showMoreTextModal && (
          <InfoModal
            title={label ?? ''}
            showInfoModal={showMoreTextModal}
            closeModal={() => setShowMoreTextModal(false)}
            infoText={stringValue}
          />
        )}
      </div>
    );
  } else {
    return (
      <div
        style={{
          paddingTop: 'env(safe-area-inset-top)',
          paddingBottom: 'env(safe-area-inset-bottom)',
          paddingLeft: 'env(safe-area-inset-left)',
          paddingRight: 'env(safe-area-inset-right)',
        }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          className={classes.textFieldcontainer}
          style={customStyle}>
          {label && (
            <Grid item>
              <Typography className={classes.textFieldLabel}>
                {label}
                {required && <span style={{color: 'red'}}> *</span>}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <TextField
              value={displayValue}
              InputProps={{
                readOnly: true,
                className: classes.root,
                endAdornment: stringValue.length > textLength && (
                  <div style={{paddingRight: 18}}>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{cursor: 'pointer'}}
                      onClick={handleMoreClick}>
                      More
                    </Typography>
                  </div>
                ),
              }}
            />
          </Grid>
        </Grid>
        {showMoreTextModal && (
          <InfoModal
            title={label ?? ''}
            showInfoModal={showMoreTextModal}
            closeModal={() => setShowMoreTextModal(false)}
            infoText={stringValue}
          />
        )}
      </div>
    );
  }
};

export default CustomTextFieldTP;
