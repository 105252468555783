import React, {useState, useCallback, useMemo} from 'react';
import {CircularProgress, Grid} from '@mui/material';
import {NetworkService, ToasterService} from '@app/services';
import {HTTP_OK} from '@app/common/constants';
import {ToastType} from '@app/services/toaster';
import {CustomModal} from '@app/components/widgets/CustomModal';
import CustomTitle from '../common-component/CustomTitle';

interface FilePreviewProps {
  openModal: boolean;
  closeModal: () => void;
  fileName: string;
  blobFolderName: string;
  url: string;
}

const FilePreviewContent: React.FC<{
  fileExtension: string | undefined;
  fileData: string | undefined;
  loading: boolean;
}> = ({fileExtension, fileData, loading}) => {
  if (loading) {
    return <CircularProgress />;
  }
  if (fileExtension === 'pdf') {
    return (
      <embed
        style={{
          width: '100%',
        }}
        height="500"
        src={`data:application/pdf;base64,${fileData}`}
      />
    );
  } else if (
    fileExtension === 'jpg' ||
    fileExtension === 'jpeg' ||
    fileExtension === 'png'
  ) {
    const imageData = `data:image/${fileExtension};base64,${fileData}`;
    return (
      <img
        src={imageData}
        alt={' File type not supported'}
        width="800px"
        height="800px"
      />
    );
  } else {
    return <div>File type not supported.</div>;
  }
};

export const FilePreviewTP: React.FC<FilePreviewProps> = ({
  openModal,
  closeModal,
  fileName,
  blobFolderName,
  url,
}) => {
  const filePreviewAPICall = useCallback(async () => {
    try {
      const payload = {
        blobName: fileName,
        blobFolderName,
      };
      const response: any = await NetworkService.post(url, payload);
      if (HTTP_OK !== response?.status) {
        ToasterService.showToaster('Failed to Fetch Data.', ToastType.ERROR);
        return;
      }
      if (response.data) {
        return response.data;
      }
    } catch (err) {
      console.log(err);
    }
  }, [fileName, blobFolderName]);

  const fileExtension = fileName.split('.').pop()?.toLowerCase();
  const [fileData, setFileData] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  useMemo(async () => {
    try {
      const data = await filePreviewAPICall();
      setFileData(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [filePreviewAPICall]);

  return (
    <CustomModal
      open={openModal}
      onClose={closeModal}
      header={<CustomTitle title={`File Name: ${fileName}`} variant={'h6'} />}
      children={
        <Grid
          container
          style={{height: 500}}
          justifyContent="center"
          alignItems="center">
          <FilePreviewContent
            fileExtension={fileExtension}
            fileData={fileData}
            loading={loading}
          />
        </Grid>
      }
    />
  );
};
