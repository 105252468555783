import {useState, useEffect} from 'react';
import {CustomModal} from '@app/components/widgets/CustomModal';
import {RejectWithReasonModalTP} from '../common-component/RejectWithReasonModalTP';
import {ApproveModalTP} from '../common-component/ApprovalModalTP';
import {Grid, TextField, Box} from '@mui/material';
import {useStyles} from './styles';
import CustomTitle from '../common-component/CustomTitle';
import {FooterButtonTP} from '../common-component/footerButtonTP';
import {useSelector, useDispatch} from 'react-redux';
import {
  chemistRequestsCreator,
  approveChemistRequestCreator,
  rejectChemistRequestCreator,
  doctorApprovalSelector,
  doctorApprovalActions,
} from '@app/screens/module-specific-utilities/pages/doctor-approval/redux';
import {MarkNotification} from '@app/components/right-sidebar/NotificationModalHelper';
import {CircularProgress} from '@material-ui/core';
import CustomTextFieldTP from '../common-component/CustomTextFieldTP';

const ChemistRemoveInfo = (props: any) => {
  const classes = useStyles();
  const loader = useSelector(doctorApprovalSelector.getChemistRemoveLoading());

  return (
    <Grid container style={{flexDirection: 'row'}}>
      {loader && (
        <Box
          sx={{display: 'flex', justifyContent: 'center', mt: 10}}
          className={classes.loader}>
          <CircularProgress />
        </Box>
      )}
      <Box className={classes.flexSpaceBetween}>
        <Grid container className={classes.boxContainer}>
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Name'}
            value={props.data.name}
            multiline={true}
            required={true}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Address'}
            value={props.data.address}
            multiline={true}
            required={true}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'City'}
            value={props.data.cityName}
            required={true}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Email'}
            value={props.data.email}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Landmark'}
            value={props.data.landmark}
            multiline={true}
            required={true}
          />
        </Grid>
        <Grid container>
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Mobile Number'}
            value={props.data.mobile}
            required={true}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Pincode'}
            value={props.data.pinCode}
            required={true}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'GST Number'}
            value={props.data.gstNumber}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Drug License Number'}
            value={props.data.drugLicense}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Area'}
            value={props.data.territoryName}
            required={true}
          />
        </Grid>
      </Box>
      <Grid container style={{marginTop: 20}}>
        <CustomTitle
          title={'Reason for Removing Chemist'}
          variant={'h6'}
          required={true}
        />
      </Grid>
      <TextField
        defaultValue={props.data.reasonForRemovalName}
        value={props.data.reasonForRemovalName}
        InputProps={{
          readOnly: true,
          className: classes.root,
        }}
      />
    </Grid>
  );
};

export const ChemistRemoveRequest = (props: any) => {
  const dispatch = useDispatch();
  const chemistRemoveData = useSelector(
    doctorApprovalSelector.getChemistRequests(),
  );
  const [viewRejectModal, setViewRejectModal] = useState<boolean>(false);
  const [approvePopup, setApprovePopup] = useState<boolean>(false);
  const chemistRemovalCompleted = useSelector(
    doctorApprovalSelector.getChemistRemoveDone(),
  );

  useEffect(() => {
    if (chemistRemovalCompleted) {
      if (props?.notificationId) {
        MarkNotification(props?.notificationId);
      }
    }
  }, [chemistRemovalCompleted]);

  const closeDocPopup = () => {
    props.onClose();
  };
  const closeRejectModal = () => {
    setViewRejectModal(false);
    setApprovePopup(false);
  };

  const body = {
    partyId: chemistRemoveData.partyId,
    partyName: chemistRemoveData.name,
    reason: '',
    patchName: '',
  };

  const handleChemistRemovalApprove = () => {
    dispatch(approveChemistRequestCreator(body));
  };

  const handleChemistRemovalRejection = (reason: string) => {
    const updatedBody = {...body, reason: reason};
    dispatch(rejectChemistRequestCreator(updatedBody));
    MarkNotification(props.notificationId);
  };

  useEffect(() => {
    dispatch(chemistRequestsCreator({partyId: props.partyId}));
  }, []);

  useEffect(
    function closeModal() {
      if (chemistRemovalCompleted) {
        closeDocPopup();
        dispatch(doctorApprovalActions.resetChemistRemoveDone(''));
      }
    },
    [chemistRemovalCompleted],
  );

  return (
    <div>
      <CustomModal
        fullWidth={true}
        open={props.viewPopupChemist}
        onClose={closeDocPopup}
        header={
          <CustomTitle title={'Basic  Customer Information'} variant={'h6'} />
        }
        children={<ChemistRemoveInfo data={chemistRemoveData} />}
        footer={
          <FooterButtonTP
            firstLabel={'Approve'}
            secondLabel={'Reject with Reason'}
            firstButtonStyle={{textTransform: 'none'}}
            secondButtonStyle={{textTransform: 'none'}}
            firstOnClick={() => setApprovePopup(true)}
            secondOnClick={() => setViewRejectModal(true)}
          />
        }
      />
      {viewRejectModal && (
        <RejectWithReasonModalTP
          viewRejectModal={viewRejectModal}
          closeModal={() => {
            closeRejectModal();
          }}
          reasonText={handleChemistRemovalRejection}
        />
      )}
      {approvePopup && (
        <ApproveModalTP
          viewApproveModal={approvePopup}
          closeModal={() => {
            setApprovePopup(false);
          }}
          onApprove={handleChemistRemovalApprove}
        />
      )}
    </div>
  );
};
