import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: '282.7px',
    Height: 42.7,
    borderRadius: 6.7,
    borderWidth: 1.3,
    borderColor: '#caccd1',
    backgroundColor: '#f2f2f2',
    fontFamily: 'Poppins',
    fontSize: 11.3,
    color: '#1c1939',
    lineHeight: '20px',
    padding: '0px 5px',
  },
  root2: {
    '& .MuiInputBase-input': {
      width: 400,
      height: 42.7,
      borderRadius: 6.7,
      borderWidth: 1.3,
      borderColor: '#caccd1',
      backgroundColor: '#f2f2f2',
      fontFamily: 'Poppins',
      fontSize: 11.3,
      color: '#1c1939',
      padding: '0px 18px',
    },
  },
  rejectModalTextField: {
    width: 400,
    fontFamily: 'Poppins',
    fontSize: 16,
  },
  textFieldcontainer: {
    width: 530,
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  textFieldLabel: {
    color: '#1c1939',
    fontSize: 12,
    fontFamily: 'Poppins',
  },
  TextFieldRowContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    height: 53.4,
  },
  rejectBtn: {
    width: 200,
    height: 42.7,
    borderRadius: 6.7,
    borderWidth: 1.3,
    borderColor: '#322b7c',
    backgroundColor: '#322b7c',
    fontFamily: 'Poppins',
    fontSize: 12.7,
  },
  approveBtn: {
    width: 200,
    height: 42.7,
    borderRadius: 6.7,
    borderWidth: 1.3,
    borderColor: '#322b7c',
    fontFamily: 'Poppins',
    fontSize: 12.7,
  },
  searchField: {
    border: 'transparent',
    borderRadius: 5,
    background: '#f2f2f2',
    padding: '0px',
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    color: '#000',
    fontSize: 12,
  },
  padding5: {
    padding: '5px',
  },
  error: {
    color: '#E36565',
    position: 'absolute',
    top: -20,
  },
  clear: {
    position: 'absolute',
    right: 10,
    top: 9,
    backgroundColor: '#f2f2f2',
    cursor: 'pointer',
  },
  leftIcon: {
    position: 'absolute',
    right: 40,
    top: 15,
    backgroundColor: '#f2f2f2',
    cursor: 'pointer',
  },
  searchSuggestion: {
    width: '100%',
    backgroundColor: '#f2f2f2',
    top: 54,
    zIndex: 1,
    position: 'absolute',
    overflow: 'auto',
    maxHeight: 300,
    '& ul': {padding: 0},
  },
});
