import React from 'react';
import {Typography, TypographyProps} from '@mui/material';

interface CustomTitleProps {
  title: string;
  variant: TypographyProps['variant'];
  required?: boolean;
}

const CustomTitle: React.FC<CustomTitleProps> = ({
  title,
  variant,
  required = false,
}) => {
  return (
    <Typography
      color="#1c1939"
      variant={variant}
      fontFamily="Poppins"
      style={{
        marginBottom: 5,
      }}>
      {title}
      {required && <span style={{color: 'red'}}> *</span>}
    </Typography>
  );
};

export default CustomTitle;
